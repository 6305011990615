import { t } from "@lingui/macro";

export const metadata = {
  home: {
    title: {
      // key: (
      //   <Trans>
      //     Welcome to the largest digital ecosystem in the WealthTech sector.
      //   </Trans>
      // ),
      label: t`Welcome to the largest digital ecosystem in the WealthTech sector.`,
    },
    description: {
      // key: (
      //   <Trans>
      //     With more than 20 years of experience, Allfunds is a unique platform
      //     within the WealthTech sector. With the objective of offering access to
      //     the world's largest fund distribution network.
      //   </Trans>
      // ),
      label: t`
      With more than 20 years of experience, Allfunds is a unique platform within the WealthTech sector. With the objective of offering access to the world's largest fund distribution network.
    `,
    },
  },
  about: {
    title: {
      // key: (
      //   <Trans>
      //     Discover what Allfunds is and all the digital services that offers.
      //   </Trans>
      // ),
      label: t`
        Discover what Allfunds is and all the digital services that offers.
      `,
    },
    description: {
      // key: (
      //   <Trans>
      //     Allfunds offers a large portfolio of services, being one of the
      //     leaders in the WealthTech and funds sector. Offering digital tools for
      //     analysis,reporting, data analytics, custom software solutions, ESG for
      //     both Fund Houses and Distributors.
      //   </Trans>
      // ),
      label: t`
        Allfunds offers a large portfolio of services, being one of the leaders
        in the WealthTech and funds sector. Offering digital tools for
        analysis,reporting, data analytics, custom software solutions, ESG for
        both Fund Houses and Distributors.
      `,
    },
  },
  about_team: {
    title: {
      // key: (
      //   <Trans>
      //     Discover the core team that drives the WealthTech sector
      //     leader,Allfunds.
      //   </Trans>
      // ),
      label: t`
        Discover the core team that drives the WealthTech sector
        leader,Allfunds.
      `,
    },
    description: {
      // key: (
      //   <Trans>
      //     The backbone of Allfunds is based on the executive committee and the
      //     board of directors. Both are composed of top professionals from the
      //     asset management, financial, and WealthTech sectorsx
      //   </Trans>
      // ),
      label: t`
        The backbone of Allfunds is based on the executive committee and the
        board of directors. Both are composed of top professionals from the
        asset management, financial, and WealthTech sectorsx
      `,
    },
  },
  about_history: {
    title: {
      // key: (
      //   <Trans>
      //     Learn about our origins, positioning, strengthening and expansion.
      //   </Trans>
      // ),
      label: t`
        Learn about our origins, positioning, strengthening and expansion.
      `,
    },
    description: {
      // key: (
      //   <Trans>
      //     Allfunds is a highly valued international provider in the WealthTech
      //     and fund industry, that covers overing all global markets, the best
      //     digital services and adding distinctive value to all our clients.
      //   </Trans>
      // ),
      label: t`
        Allfunds is a highly valued international provider in the WealthTech and
        fund industry, that covers overing all global markets, the best digital
        services and adding distinctive value to all our clients.
      `,
    },
  },
  investors: {
    title: {
      // key: <Trans>Find out more about our public financial data.</Trans>,
      label: t`Find out more about our public financial data.`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Within the Investor Relations section, you can get all the necessary
      //     information at a public level, where you can corroborate the great
      //     service and value offered by Allfunds, with its top digital ecosystem
      //     -Connect.
      //   </Trans>
      // ),
      label: t`
        Within the Investor Relations section, you can get all the necessary
        information at a public level, where you can corroborate the great
        service and value offered by Allfunds, with its top digital ecosystem
        -Connect.
      `,
    },
  },
  investors_about: {
    title: {
      // key: (
      //   <Trans>
      //     Leading by example in the new digital service offering for the
      //     WealthTech Sector.
      //   </Trans>
      // ),
      label: t`
        Leading by example in the new digital service offering for the
        WealthTech Sector.
      `,
    },
    description: {
      // key: (
      //   <Trans>
      //     Allfunds&apos strategy is based on innovation, evolution and adaptation
      //     ahead of market trends. Allfunds offers a digital ecosystem which
      //     provides a perfect environment for both distributors and Fund Houses
      //     that allows them to maximize their commercial and business development
      //     efforts.
      //   </Trans>
      // ),
      label: t`
        Allfunds' strategy is based on innovation, evolution and adaptation
        ahead of market trends. Allfunds offers a digital ecosystem which
        provides a perfect environment for both distributors and Fund Houses
        that allows them to maximize their commercial and business development
        efforts.
      `,
    },
  },
  governance_group: {
    title: {
      // key: (
      //   <Trans>
      //     Discover the parent company of the Allfunds - Allfunds Group Plc.
      //   </Trans>
      // ),
      label: t`
        Discover the parent company of the Allfunds - Allfunds Group Plc.
      `,
    },
    description: {
      // key: (
      //   <Trans>
      //     Allfunds is a UK-based company listed on Euronext Amsterdam The
      //     Spanish company Allfunds Bank, which holds the banking license and
      //     operates as a regulated financial institution, has only one indirect
      //     shareholder:Allfunds. Having a well-integrated and aligned board of
      //     directors.
      //   </Trans>
      // ),
      label: t`
        Allfunds is a UK-based company listed on Euronext Amsterdam The Spanish
        company Allfunds Bank, which holds the banking license and operates as a
        regulated financial institution, has only one indirect
        shareholder:Allfunds. Having a well-integrated and aligned board of
        directors.
      `,
    },
  },
  governance_bank: {
    title: {
      // key: <Trans>Learn more about Allfunds Bank S.A.U.</Trans>,
      label: t`Learn more about Allfunds Bank S.A.U.`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Allfunds Plc is listed on Euronext Amsterdam. Allfunds Plc is the only
      //     indirect stakeholder of Allfunds Bank, a regulated Spanish corporation
      //     with a banking licence.
      //   </Trans>
      // ),
      label: t`
        Allfunds Plc is listed on Euronext Amsterdam. Allfunds Plc is the only
        indirect stakeholder of Allfunds Bank, a regulated Spanish corporation
        with a banking licence.
      `,
    },
  },
  share: {
    title: {
      // key: <Trans>Discover all the data regarding Allfunds shares</Trans>,
      label: t`Discover all the data regarding Allfunds shares`,
    },
    description: {
      // key: (
      //   <Trans>
      //     In this section, you will be able to find data about the Allfunds
      //     share price, shareholding structure, dividends and more.
      //   </Trans>
      // ),
      label: t`
        In this section, you will be able to find data about the Allfunds share
        price, shareholding structure, dividends and more.
      `,
    },
  },
  financial_reports: {
    title: {
      // key: (
      //   <Trans>
      //     Access all the financial information you need about Allfunds.
      //   </Trans>
      // ),
      label: t`
        Access all the financial information you need about Allfunds.
      `,
    },
    description: {
      // key: (
      //   <Trans>
      //     Get all the necessary reports related to financial, annual reports,
      //     ESG.We offer a complete backlog from different years, fulfilling an
      //     excellent transparency within the financial sector.
      //   </Trans>
      // ),
      label: t`
        Get all the necessary reports related to financial, annual reports,
        ESG.We offer a complete backlog from different years, fulfilling an
        excellent transparency within the financial sector.
      `,
    },
  },
  calendar: {
    title: {
      // key: <Trans>Explore the Allfunds Financial Calendar.</Trans>,
      label: t`Explore the Allfunds Financial Calendar.`,
    },
    description: {
      // key: (
      //   <Trans>
      //     This section provides access to all the necessary financial
      //     information,both current and past. You can also find information on
      //     events in connection with the engagement with our stakeholders.
      //   </Trans>
      // ),
      label: t`
        This section provides access to all the necessary financial
        information,both current and past. You can also find information on
        events in connection with the engagement with our stakeholders.
      `,
    },
  },
  ipo: {
    title: {
      // key: <Trans>Get all the IPO information you may need.</Trans>,
      label: t`Get all the IPO information you may need.`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Allfunds went public in 2021 in Euronext Amsterdam. Access all records
      //     and relevant information pertaining to this transaction.
      //   </Trans>
      // ),
      label: t`
        Allfunds went public in 2021 in Euronext Amsterdam. Access all records
        and relevant information pertaining to this transaction.
      `,
    },
  },
  shareholder_meetings: {
    title: {
      // key: <Trans>Obtain documentation on the shareholders meetings.</Trans>,
      label: t`Obtain documentation on the shareholders meetings.`,
    },
    description: {
      // key: (
      //   <Trans>
      //     In this section you will be able to obtain data and information based
      //     on the different AGM (Annual General Meeting) and shareholders
      //     meetings.
      //   </Trans>
      // ),
      label: t`
        In this section you will be able to obtain data and information based on
        the different AGM (Annual General Meeting) and shareholders meetings.
      `,
    },
  },
  faqs: {
    title: {
      // key: <Trans>Allfunds – Investor FAQ</Trans>,
      label: t`Allfunds – Investor FAQ`,
    },
    description: {
      // key: (
      //   <Trans>
      //     FAQ cover commonly asked questions regarding Allfunds as a listed
      //     company, including share information, applicable regulation, banking
      //     license information and exchange trading.
      //   </Trans>
      // ),
      label: t`
        FAQ cover commonly asked questions regarding Allfunds as a listed
        company, including share information, applicable regulation, banking
        license information and exchange trading.
      `,
    },
  },
  investors_contact: {
    title: {
      // key: <Trans>Allfunds – Investor Relations contact</Trans>,
      label: t`Allfunds – Investor Relations contact`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Contact information for our Madrid-based investor relations team
      //   </Trans>
      // ),
      label: t`
        Contact information for our Madrid-based investor relations team
      `,
    },
  },
  esg_approach: {
    title: {
      // key: <Trans>Allfunds – ESG strategy </Trans>,
      label: t`Allfunds – ESG strategy`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Learn more about the Environmental, Social, and Governance principles
      //     are applied in our day-to-day operations and business development at
      //     Allfunds. To advance and remain in the forefront, we strive to
      //     incorporate the highest standards applicable to our industry.
      //   </Trans>
      // ),
      label: t`
        Learn more about the Environmental, Social, and Governance principles
        are applied in our day-to-day operations and business development at
        Allfunds. To advance and remain in the forefront, we strive to
        incorporate the highest standards applicable to our industry.
      `,
    },
  },
  esg_reports: {
    title: {
      // key: <Trans>Allfunds - ESG reports</Trans>,
      label: t`Allfunds - ESG reports`,
    },
    description: {
      // key: <Trans>Access past and present Allfunds ESG Reports</Trans>,
      label: t`Access past and present Allfunds ESG Reports`,
    },
  },
  strategic_pillars: {
    title: {
      // key: <Trans>Allfunds - ESG Drivers and strategy</Trans>,
      label: t`Allfunds - ESG Drivers and strategy`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Information about Allfunds’ ESG drivers and strategic pillars: Ethics
      //     and Governance, Talent Management, Digitalisation and
      //     Innovation,Responsible Investment, Social Commitment, Environmental
      //     Protection
      //   </Trans>
      // ),
      label: t`
        Information about Allfunds’ ESG drivers and strategic pillars: Ethics
        and Governance, Talent Management, Digitalisation and
        Innovation,Responsible Investment, Social Commitment, Environmental
        Protection
      `,
    },
  },
  policies_statements: {
    title: {
      // key: <Trans>Allfunds - ESG Policies and Statements</Trans>,
      label: t`Allfunds - ESG Policies and Statements`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Access documents and information regarding Allfunds’
      //     environmental,social and governance policies
      //   </Trans>
      // ),
      label: t`
        Access documents and information regarding Allfunds’
        environmental,social and governance policies
      `,
    },
  },
  careers: {
    title: {
      // key: <Trans>Career opportunities at Allfunds</Trans>,
      label: t`Career opportunities at Allfunds`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Learn more about employment opportunities and vacancies at
      //     Allfunds,corporate culture and values.
      //   </Trans>
      // ),
      label: t`
        Learn more about employment opportunities and vacancies at
        Allfunds,corporate culture and values.
      `,
    },
  },
  media: {
    title: {
      // key: <Trans>Allfunds - News and Media</Trans>,
      label: t`Allfunds - News and Media`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Here you will find the latest press releases and media mentions relate
      //     to Allfunds.
      //   </Trans>
      // ),
      label: t`
        Here you will find the latest press releases and media mentions relate
        to Allfunds.
      `,
    },
  },
  contact: {
    title: {
      // key: <Trans>Allfunds – Contact Form and office information</Trans>,
      label: t`Allfunds – Contact Form and office information`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Contact Allfunds using the form below if you are a distributor, Fund
      //     House, or have any other questions, and we will respond as soon as
      //     possible.
      //   </Trans>
      // ),
      label: t`
        Contact Allfunds using the form below if you are a distributor, Fund
        House, or have any other questions, and we will respond as soon as
        possible.
      `,
    },
  },
  distributors: {
    title: {
      // key: <Trans>Allfunds – Our best in class Distributor solutions</Trans>,
      label: t`Allfunds – Our best in class Distributor solutions`,
    },
    description: {
      // key: (
      //   <Trans>
      //     Since 2000, Allfunds has provided distributors with access to a
      //     marketplace of over 200,000 funds, all within a digital environment
      //     outfitted with user-friendly, powerful tools to help them strengthen
      //     their own product offering, better their relationship with clients,
      //     and simplify fund management.
      //   </Trans>
      // ),
      label: t`
        Since 2000, Allfunds has provided distributors with access to a
        marketplace of over 200,000 funds, all within a digital environment
        outfitted with user-friendly, powerful tools to help them strengthen
        their own product offering, better their relationship with clients, and
        simplify fund management.
      `,
    },
  },
  distributors_blockchain: {
    title: {
      // key: <Trans>Allfunds Blockchain</Trans>,
      label: t`Allfunds Blockchain`,
    },
    description: {
      // key: (
      //   <Trans>
      //     A specialized software company focused on providing solutions for the
      //     entire funds sector, not only Allfunds and its clients.
      //   </Trans>
      // ),
      label: t`
        A specialized software company focused on providing solutions for the
        entire funds sector, not only Allfunds and its clients.
      `,
    },
  },
  fund_houses: {
    title: {
      // key: (
      //   <Trans>
      //     Allfunds – The leading distribution platform for Fund Houses
      //   </Trans>
      // ),
      label: t`
        Allfunds – The leading distribution platform for Fund Houses
      `,
    },
    description: {
      // key: (
      //   <Trans>
      //     Allfunds provides Fund Houses with access to a large network of
      //     institutions as well as advanced digital technologies to maximize
      //     distribution efforts under a single agreement.
      //   </Trans>
      // ),
      label: t`
        Allfunds provides Fund Houses with access to a large network of
        institutions as well as advanced digital technologies to maximize
        distribution efforts under a single agreement.
      `,
    },
  },
};
