import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { metadata } from "../utils/metadata";

function SEO({
  title = metadata.home.title.label,
  description = metadata.home.description.label,
  children,
}) {
  const noIndexPaths = ["/widgets/"];
  const { pathname } = useLocation();
  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <title>{title}</title>
      <meta id="description" name="description" content={description} />
      {noIndexPaths.some((item) => pathname.includes(item)) && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {children}
    </Helmet>
  );
}

export default SEO;
