// import { Input } from "@allfunds/next-react-ui-kit"
import {
  faCheck,
  faFileContract,
  faFileInvoice,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t, Trans } from "@lingui/macro";
import {
  Box,
  CssBaseline,
  Divider,
  Grid,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocalization } from "gatsby-theme-i18n";
import React, { useState } from "react";
import Container from "../../../components/Container";
import CustomizedIcon from "../../../components/CustomizedIcon";
import Footer from "../../../components/footer";
import LoadingButton from "../../../components/LoadingButton";
import Seo from "../../../components/seo";
import logo from "../../../images/logo-negative.png";
import mainstreetLogo from "../../../images/mainstreet.png";
import macImage from "../../../images/products/esg/mac.png";
import mountainImage from "../../../images/products/esg/mountain.png";
import patternsImage from "../../../images/products/esg/patterns.png";
import Mail from "../../../services/MailService";
import { theme } from "../../../theme";
import { isValidMail } from "../../../utils/validations";

const metadata = {
  title: "Allfunds - ESG",
  description: "Accede gratis al producto de ESG.",
};

const PAIs = [
  {
    icon: faFileContract,
    title: <Trans>Ex-Ante</Trans>,
    description: (
      <Trans>
        PAI data at Issuer Level Unique PAI Scores (1 to 5) simple for portfolio
        managers to implement and incorporate into DNSH criteria.
      </Trans>
    ),
  },
  {
    icon: faFileInvoice,
    title: <Trans>Ex-Post</Trans>,
    description: (
      <Trans>
        Comprehensive PAI report at fund level. Aggregated PAI figures for SFDR
        Periodic Report.
      </Trans>
    ),
  },
];

const ESG_PLATFORM = [
  {
    description: (
      <Trans>
        ESGeverything is your one-stop shop for your sustainability
        requirements.
      </Trans>
    ),
    list: [
      <Trans>Managing ESG risks and opportunities</Trans>,
      <Trans>Compliance to evolving sustainability regulation</Trans>,
      <Trans>Making informed sustainable investment decisions</Trans>,
      <Trans>Avoiding greenwashing risk</Trans>,
    ],
  },
  {
    description: (
      <Trans>
        Unique and robust methodologies form the foundation of ESGeverything,
        granting professional investors access to:
      </Trans>
    ),
    list: [
      <Trans>ESG ratings for companies, funds, ETFs & bonds</Trans>,
      <Trans>Exclusion list and controversial activity monitoring tools</Trans>,
      <Trans>Portfolio Analysis tool and automated ESG reports</Trans>,
    ],
  },
];

const PRODUCTS = {
  landing: "Landing ESG",
  pai: "Landing ESG - PAI",
  everything: "Landing ESG - Everything",
};

function EsgPaiPage() {
  const { locale } = useLocalization();
  const [emails, setEmails] = useState(
    Object.keys(PRODUCTS).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {}),
  );
  const [showMessage, setShowMessage] = useState({});
  const [showError, setShowError] = useState({});
  const [loading, setLoading] = useState({});
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const renderInput = ({ key, light = false }) => {
    return (
      <Stack spacing={1}>
        <Stack direction="row" spacing={2}>
          <TextField
            required
            fullWidth
            value={emails[key]}
            variant="outlined"
            label={<Trans>Email</Trans>}
            onChange={(e) => setEmails({ [key]: e.target.value })}
            error={showError[key]}
            sx={{
              background: light
                ? theme.palette.neutral.lightest
                : theme.palette.neutral.lighter,
              width: { xs: "60%", md: "100%" },
              borderRadius: "5px",
              ...(!showError[key] && {
                "& fieldset": { border: "none" },
              }),
            }}
          />
          <Box
            sx={{
              width: { xs: "40%", md: "218px" },
            }}
          >
            <LoadingButton
              color="corporate"
              variant="contained"
              sx={{
                width: "100% !important",
                height: "100% !important",
                boxShadow: "none",
              }}
              onClick={() => sendRequest(key)}
              loading={loading[key]}
            >
              <Typography color="white">
                <Trans>Request info</Trans>
              </Typography>
            </LoadingButton>
          </Box>
        </Stack>
        {showMessage[key] && (
          <Stack spacing={1} direction="row" alignItems="center">
            <FontAwesomeIcon icon={faCheck} color="green" />
            <Typography variant="small" color="text.light">
              {showMessage[key]}
            </Typography>
          </Stack>
        )}
        {showError[key] && (
          <Stack spacing={1} direction="row" alignItems="center">
            <FontAwesomeIcon icon={faXmark} color="red" />
            <Typography variant="small" color="text.light">
              {showError[key]}
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  };

  const sendRequest = (key) => {
    setShowMessage({});
    const data = {
      type: "product",
      to: [emails[key]],
      email: emails[key],
      subject: PRODUCTS[key],
      requestOrigin: window.location.href,
      product: "Marketplace - Request Information",
    };

    const messageError = isValidMail(emails[key]);
    if (messageError) return setShowError({ [key]: messageError });

    setLoading({ [key]: true });
    return Mail.sendMail(data, { lang: locale }).then(({ status }) => {
      if (status === 200) {
        setLoading({});
        setShowError({});
        setEmails({ ...emails, [key]: "" });
        setShowMessage({ [key]: t`Your request was successfully registered` });
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Seo title={metadata.title} description={metadata.description} />
      <CssBaseline />
      <Box>
        <Stack
          pb={6}
          spacing={2}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Container>
            <Grid container spacing={4} justifyContent="space-between">
              <Grid item xs={12} md={6}>
                <Box
                  mt={{ xs: 4, md: 0 }}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    minHeight: { xs: "400px", md: "600px" },
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={{ xs: 1, md: 2 }}
                    justifyContent={{ xs: "center", md: "start" }}
                    divider={<Divider flexItem orientation="vertical" />}
                    alignItems="center"
                    sx={{
                      width: "100%",
                      "> img": {
                        maxWidth: { xs: "40%", md: "180px" },
                      },
                    }}
                  >
                    <img alt="allfunds_logo" src={logo} />
                    <img alt="mainstreet_logo" src={mainstreetLogo} />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="h2">
                      <Trans>
                        One stop shop for your sustainability requirements
                      </Trans>
                    </Typography>
                    <Typography variant="text" color="text.light">
                      <Trans>
                        Comply with SFDR regulation and report Principal Adverse
                        Indicators for art. 8 and 9 funds, mandatory from 30th
                        June 2023.
                      </Trans>
                    </Typography>
                    {renderInput({ key: "landing" })}
                  </Stack>
                </Box>
              </Grid>
              {!isMobile && (
                <Grid item xs={12} md={6} textAlign="end">
                  <img
                    src={mountainImage}
                    alt="first_image"
                    style={{ width: "90%" }}
                  />
                </Grid>
              )}
            </Grid>
          </Container>
        </Stack>
        <Stack
          py={6}
          spacing={2}
          direction="row"
          justifyContent="space-between"
          sx={{ background: theme.palette.neutral.lighter }}
        >
          <Container>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <img
                  src={patternsImage}
                  alt="second_image"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={4}>
                  <Stack spacing={1}>
                    <Typography
                      variant="text"
                      fontWeight={600}
                      sx={{ color: theme.palette.corporate.main }}
                    >
                      <Trans>PAI</Trans>
                    </Typography>
                    <Typography variant="h3">
                      <Trans>
                        Allfunds, via MainStreet Partners, supports fund houses
                        in assessing PAI indicators at fund level
                      </Trans>
                    </Typography>
                    <Typography color="text.light">
                      <Trans>
                        Our solution addresses the regulatory need (SFDR) in a
                        straightforward, practical, and affordable manner. PAI
                        disclosure is mandatory starting June 2023 to enhance
                        investment transparency.
                      </Trans>
                    </Typography>
                  </Stack>
                  <Stack>
                    <Grid container spacing={2}>
                      {PAIs.map(({ icon, title, description }) => (
                        <Grid item xs={12} md={6}>
                          <Stack spacing={1}>
                            <CustomizedIcon
                              size={24}
                              icon={icon}
                              padding={1}
                              color={theme.palette.corporate.main}
                              backgroundColor={theme.palette.corporate.light}
                            />
                            <Typography fontWeight={700}>{title}</Typography>
                            <Typography color="text.light">
                              {description}
                            </Typography>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                  {renderInput({ key: "pai", light: true })}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Stack>
        <Stack
          py={6}
          spacing={2}
          direction="row"
          justifyContent="space-between"
          sx={{ paddingRight: "0px !important" }}
        >
          <Container>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Typography
                    variant="text"
                    fontWeight={600}
                    sx={{ color: theme.palette.corporate.main }}
                  >
                    <Trans>ESGeverything Platform</Trans>
                  </Typography>
                  <Typography variant="h3">
                    <Trans>
                      From proprietary ESG ratings and data to non-financial
                      reporting solutions
                    </Trans>
                  </Typography>
                  {ESG_PLATFORM.map(({ description, list }) => (
                    <Stack spacing={1}>
                      <Typography color="text.light">{description}</Typography>
                      <ul>
                        {list.map((item) => (
                          <li>
                            <Typography color="text.light">{item}</Typography>
                          </li>
                        ))}
                      </ul>
                    </Stack>
                  ))}
                  {renderInput({ key: "everything" })}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  src={macImage}
                  alt="last_iamge"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Container>
        </Stack>
        <Footer small light />
      </Box>
    </ThemeProvider>
  );
}

export default EsgPaiPage;
